import React from 'react';
import Home_Page from '../src/Pages/Home_Page';
import Contact_Page from '../src/Pages/Contact_Page';
import Services_Page from "./Pages/Services_Page";

// Import your exported JSON content
import homePageContent from '../src/Pages/Page_JSON/Home_page.json';
import contactPageContent from '../src/Pages/Page_JSON/Contact_Page.json';
import servicesPageContent from '../src/Pages/Page_JSON/Services_Page.json';
import {Route, Routes} from "react-router-dom";

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Home_Page content={homePageContent}/>} />
            <Route path="/contact" element={<Contact_Page content={contactPageContent}/>} />
            <Route path="/services" element={<Services_Page content={servicesPageContent} curMode={"WebDev"}/>} />
        </Routes>
    );
};

export default App;