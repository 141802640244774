import {
    Accordion,
    Alert,
    Anchor,
    Badge,
    Blockquote,
    Breadcrumbs,
    Burger,
    Button,
    Card,
    Checkbox,
    Chip,
    ColorInput,
    ColorPicker,
    Divider,
    Drawer,
    Image as MantineImage,
} from '@mantine/core';
import { Builder } from '@builder.io/react';

const componentsMapPart1 = {
    Accordion: {
        component: Accordion,
        inputs: [
            {
                name: 'children',
                type: 'uiBlocks',
            },
        ],
    },
    Alert: {
        component: Alert,
        inputs: [
            { name: 'title', type: 'text' },
            { name: 'children', type: 'uiBlocks' },
            { name: 'color', type: 'string' },
        ],
    },
    Anchor: {
        component: Anchor,
        inputs: [
            { name: 'href', type: 'url' },
            { name: 'children', type: 'text' },
        ],
    },
    Badge: {
        component: Badge,
        inputs: [
            { name: 'children', type: 'text' },
            { name: 'color', type: 'string' },
            { name: 'variant', type: 'string' },
        ],
    },
    Blockquote: {
        component: Blockquote,
        inputs: [
            { name: 'cite', type: 'text' },
            { name: 'children', type: 'text' },
        ],
    },
    Breadcrumbs: {
        component: Breadcrumbs,
        inputs: [
            {
                name: 'children',
                type: 'list',
                subFields: [{ name: 'text', type: 'text' }],
            },
        ],
    },
    Burger: {
        component: Burger,
        inputs: [
            { name: 'opened', type: 'boolean' },
        ],
    },
    Button: {
        component: Button,
        inputs: [
            { name: 'children', type: 'text' },
            { name: 'color', type: 'string' },
            { name: 'variant', type: 'string' },
            { name: 'onClick', type: 'function' },
        ],
    },
    Card: {
        component: Card,
        inputs: [
            { name: 'children', type: 'uiBlocks' },
        ],
    },
    Checkbox: {
        component: Checkbox,
        inputs: [
            { name: 'label', type: 'text' },
            { name: 'checked', type: 'boolean' },
            { name: 'onChange', type: 'function' },
        ],
    },
    Chip: {
        component: Chip,
        inputs: [
            { name: 'label', type: 'text' },
            { name: 'checked', type: 'boolean' },
            { name: 'onChange', type: 'function' },
        ],
    },
    ColorInput: {
        component: ColorInput,
        inputs: [
            { name: 'value', type: 'text' },
            { name: 'onChange', type: 'function' },
        ],
    },
    ColorPicker: {
        component: ColorPicker,
        inputs: [
            { name: 'value', type: 'text' },
            { name: 'onChange', type: 'function' },
        ],
    },
    Divider: {
        component: Divider,
        inputs: [],
    },
    Drawer: {
        component: Drawer,
        inputs: [
            { name: 'opened', type: 'boolean' },
            { name: 'children', type: 'uiBlocks' },
        ],
    },
    MantineImage: {  // Adding the Image component
        component: MantineImage,
        inputs: [
            { name: 'src', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'] },
            { name: 'alt', type: 'string' },
            { name: 'width', type: 'string' },
            { name: 'height', type: 'string' },
            { name: 'fit', type: 'string', enum: ['cover', 'contain', 'fill', 'none', 'scale-down'] },
            { name: 'radius', type: 'string' },
        ],
    },
};

// Register components with Builder.io
Object.keys(componentsMapPart1).forEach(key => {
    Builder.registerComponent(componentsMapPart1[key].component, {
        name: key,
        inputs: componentsMapPart1[key].inputs,
    });
});

export default componentsMapPart1;