import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CatchAllRoute from './CatchAllRoute';
import './builder-settings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
            <Router>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="*" element={<CatchAllRoute />} />
                </Routes>
            </Router>
        </MantineProvider>
    </React.StrictMode>
);

reportWebVitals();