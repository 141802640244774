// builder-settings.js
import { Builder } from '@builder.io/react';
import componentsMapPart1 from './components-map-part1';
import componentsMapPart2 from './components-map-part2';

// Register all components dynamically
const registerComponents = (componentsMap) => {
    Object.keys(componentsMap).forEach((key) => {
        const { component, inputs } = componentsMap[key];
        Builder.registerComponent(component, {
            name: key,
            inputs: inputs,
        });
    });
};

registerComponents(componentsMapPart1);
registerComponents(componentsMapPart2);