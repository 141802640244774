import {
    Timeline,
    TimelineItem,
    FileInput,
    Grid,
    Group,
    Image,
    Input,
    JsonInput,
    List,
    Loader,
    LoadingOverlay,
    Menu,
    Modal,
    MultiSelect,
    Notification,
    NumberInput,
    Pagination,
} from '@mantine/core';

const componentsMapPart2 = {
    FileInput: {
        component: FileInput,
        inputs: [
            { name: 'label', type: 'text' },
            { name: 'placeholder', type: 'text' },
        ],
    },
    Grid: {
        component: Grid,
        inputs: [],
    },
    Group: {
        component: Group,
        inputs: [
            { name: 'children', type: 'list', subFields: [{ name: 'text', type: 'text' }] },
        ],
    },
    Image: {
        component: Image,
        inputs: [
            { name: 'src', type: 'text' },
            { name: 'alt', type: 'text' },
        ],
    },
    Input: {
        component: Input,
        inputs: [
            { name: 'placeholder', type: 'text' },
        ],
    },
    JsonInput: {
        component: JsonInput,
        inputs: [
            { name: 'value', type: 'text' },
        ],
    },
    List: {
        component: List,
        inputs: [
            { name: 'children', type: 'list', subFields: [{ name: 'text', type: 'text' }] },
        ],
    },
    Loader: {
        component: Loader,
        inputs: [],
    },
    LoadingOverlay: {
        component: LoadingOverlay,
        inputs: [
            { name: 'visible', type: 'boolean' },
        ],
    },
    Menu: {
        component: Menu,
        inputs: [
            { name: 'children', type: 'list', subFields: [{ name: 'text', type: 'text' }] },
        ],
    },
    Modal: {
        component: Modal,
        inputs: [
            { name: 'opened', type: 'boolean' },
        ],
    },
    MultiSelect: {
        component: MultiSelect,
        inputs: [
            { name: 'label', type: 'text' },
            { name: 'placeholder', type: 'text' },
            { name: 'data', type: 'list', subFields: [{ name: 'value', type: 'text' }, { name: 'label', type: 'text' }] },
        ],
    },
    Notification: {
        component: Notification,
        inputs: [
            { name: 'title', type: 'text' },
            { name: 'message', type: 'text' },
        ],
    },
    NumberInput: {
        component: NumberInput,
        inputs: [
            { name: 'label', type: 'text' },
            { name: 'placeholder', type: 'text' },
            { name: 'min', type: 'number' },
            { name: 'max', type: 'number' },
        ],
    },
    Pagination: {
        component: Pagination,
        inputs: [
            { name: 'total', type: 'number' },
            { name: 'page', type: 'number' },
        ],
    },
    Timeline: {
        component: Timeline,
        inputs: [
            { name: 'active', type: 'number' },
            { name: 'bulletSize', type: 'number' },
            { name: 'lineWidth', type: 'number' },
            { name: 'children', type: 'list' }
        ],
    },
    TimelineItem: {
        component: Timeline.Item,
        inputs: [
            { name: 'title', type: 'text' },
            { name: 'content', type: 'text' },
            { name: 'bullet', type: 'text' },
            { name: 'lineVariant', type: 'text' },
            { name: 'lineColor', type: 'text' },
            { name: 'color', type: 'text' },
            { name: 'lineDashArray', type: 'text' },
        ],
    },
};

export default componentsMapPart2;