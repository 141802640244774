// components/BuilderPage.jsx
import React, { useEffect, useState } from 'react';
import { BuilderComponent } from '@builder.io/react';

const Services_Page = ({ content, curMode }) => {
    const [mode, setMode] = useState(curMode || 'WebDev');

    useEffect(() => {
        if (curMode) {
            setMode(curMode);
        }
    }, [curMode]);

    return (
        <div>
            <BuilderComponent model="page" content={content} />
        </div>
    );
};

export default Services_Page;