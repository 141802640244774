// components/BuilderPage.jsx
import React from 'react';
import { BuilderComponent } from '@builder.io/react';

const Contact_Page = ({ content }) => {
    return (
        <div>
            <BuilderComponent model="page" content={content} />
        </div>
    );
};

export default Contact_Page;
